// react component
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

// component
import AppBar from './component/AppBar'

// page
import Top from './page/Top'
import Entry from './page/Entry'
import Bill from './page/Bill'
import MissingItemInput from './page/MissingItemInput'
import Order from './page/Order'
import OrderCancel from './page/OrderCancel'


import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      {/*<BrowserRouter basename="/demo">*/}
      <BrowserRouter>
        <AppBar />
        <div>
          <Route path="/" exact component={Top} />
          <Route path="/entry" exact component={Entry} />
          <Route path="/bill" component={Bill} />
          <Route path="/missingiteminput" component={MissingItemInput} />
          <Route path="/order/:slipNo" component={Order} />
          <Route path="/order/slip" component={Entry} />
          <Route path="/ordercancel" component={OrderCancel} />
        </div>
      </BrowserRouter>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
