// react component
import React from 'react';
import cx from 'clsx';

// material-ui component
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextInfoContent from '@mui-treasury/components/content/textInfo';

// material-ui extend component
import { useFourThreeCardMediaStyles } from '@mui-treasury/styles/cardMedia/fourThree';
import { useN04TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n04';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';

// original component
import OrderDialog from './OrderDialog';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 343,
    margin: 'auto',
    borderRadius: 12,
    padding: 12,
  },
  media: {
    borderRadius: 6,
  },
}));

// export default function MenuCard(props) {
export const MenuCard = React.memo(function MenuCard(props) {
  const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const textCardContentStyles = useN04TextInfoContentStyles();
  const shadowStyles = useOverShadowStyles({ inactive: true });

  const { menuItem, subMenuItem, menuSubKindItem, handleOpen } = props;
  const { category_name, menu_name, price } = props.menuItem;

  const handleClickOpen = () => {
    handleOpen(menuItem, subMenuItem, menuSubKindItem);
  };

  return (
    <Card
      className={cx(styles.root, shadowStyles.root)}
      onClick={handleClickOpen}
    >
      {/*<CardMedia*/}
      {/*  className={cx(styles.media, mediaStyles.root)}*/}
      {/*  image={*/}
      {/*    'https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80'*/}
      {/*  }*/}
      {/*/>*/}
      <CardContent>
        <TextInfoContent
          classes={textCardContentStyles}
          overline={props.menuSubKindItem.menu_sub_kind_name}
          heading={menu_name}
          body={`${price}円`}
        />
        {props.subMenuItem.map((item) => {
          return (
            <List component="div" disablePadding>
              <ListItem
                key={item.id}
                role={undefined}
                dense
                button
              >
                {item.sub_menu_name} {item.price > 0 ? '+' + item.price + '円' : ''}
              </ListItem>
            </List>
          );
        })}
      </CardContent>
    </Card>
  );
});
export default MenuCard;
