import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

// library
import axios from 'axios';

export default function OrderDialog(props) {
  const { slip, selectedMenu, handleClose, open } = props;

  if (selectedMenu.subMenu === undefined) {
    selectedMenu.subMenu = [];
  }

  const [quantity, setQuantity] = React.useState(1);
  const [selectedSubMenu, setSelectedSubMenu] = React.useState(0);

  const handleChange = (event) => {
    setSelectedSubMenu(parseInt(event.target.value));
  };

  const handleCancel = () => {
    handleClose();
    setQuantity(1);
  };

  const handleOrder = () => {
    const data = {
      slipId: slip.id,
      menuId: selectedMenu.menu.id,
      subMenuId: selectedSubMenu,
      quantity,
    };
    axios.post('http://192.168.0.8:8000/api/order', data)
      .then((results) => {
          // 以下のGoogle API のレスポンスの例を元に欲しいデータを取得
          handleClose();
          setQuantity(1);
        },
      );
  };

  const handleChangeQuantity = (event) => {
    setQuantity(parseInt(event.target.value, 10));
  };

  return (
    <div>
      {/*<Button variant="outlined" color="primary" onClick={handleClickOpen}>*/}
      {/*  Open form dialog*/}
      {/*</Button>*/}
      <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{selectedMenu.menu.menu_name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedMenu.menu.price}
          </DialogContentText>
          {/*<TextField*/}
          {/*  autoFocus*/}
          {/*  margin="dense"*/}
          {/*  id="name"*/}
          {/*  label="Email Address"*/}
          {/*  type="email"*/}
          {/*  fullWidth*/}
          {/*/>*/}
          <TextField
            id="outlined-number"
            label="数"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={quantity}
            onChange={handleChangeQuantity}
            inputProps={{
              name: 'quantity',
              // id: 'outlined-age-native-simple',
            }}
          />
          <FormControl component="fieldset">
            {/*<FormLabel component="legend">Gender</FormLabel>*/}
            <RadioGroup aria-label="subMenu" name="subMenu1" value={selectedSubMenu} onChange={handleChange}>
              {selectedMenu.subMenu.map((item) => {
                return(
                  <FormControlLabel value={item.id} control={<Radio />} label={`${item.sub_menu_name}  + ${item.price}円`} />
                );
              })}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            キャンセル
          </Button>
          <Button onClick={handleOrder} color="primary">
            注文する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
