// react component
import { React } from 'react';
import { Link } from 'react-router-dom';

// material-ui component
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },


}));

export default function NavigationBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/*<Link to="/">*/}
      {/*  <Button variant="contained" color="primary">*/}
      {/*    Top*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      {/*<Link to="/entry">*/}
      {/*  <Button variant="contained" color="primary">*/}
      {/*    Entry*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      {/*<Link to="/bill">*/}
      {/*  <Button variant="contained" color="primary">*/}
      {/*    Bill*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      {/*<Link to="/missingiteminput">*/}
      {/*  <Button variant="contained" color="primary">*/}
      {/*    MissingItemInput*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      {/*<Link to="/order">*/}
      {/*  <Button variant="contained" color="primary">*/}
      {/*    Order*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      {/*<Link to="/ordercancel">*/}
      {/*  <Button variant="contained" color="primary">*/}
      {/*    OrderCancel*/}
      {/*  </Button>*/}
      {/*</Link>*/}
      <Button variant="contained" color="secondary" size="small">
        スタッフ呼出
      </Button>
      <Button variant="contained" color="secondary" size="small">
        お会計
      </Button>
    </div>
  );
}
