/* eslint-disable no-undef */
// react component
import React from 'react';
import { Link } from 'react-router-dom';

// material-ui component
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';

// other component
import QRCode from 'qrcode.react';

// import { StarWebPrintBuilder } from '../lib/StarWebPrintBuilder';
// import { StarWebPrintTrader } from '../lib/StarWebPrintTrader';

var request = '';

function sendMessage(request) {
  // showNowPrinting();
  var url = 'http://localhost:8001/StarWebPRNT/SendMessage';
  var papertype = '';

  var trader = new StarWebPrintTrader({ url: url, papertype: papertype });

  trader.onReceive = function (response) {
    // hideNowPrinting();

    var msg = '- onReceive -\n\n';

    msg += 'TraderSuccess : [ ' + response.traderSuccess + ' ]\n';

    //      msg += 'TraderCode : [ ' + response.traderCode + ' ]\n';

    msg += 'TraderStatus : [ ' + response.traderStatus + ',\n';

    if (trader.isCoverOpen({ traderStatus: response.traderStatus })) { msg += '\tCoverOpen,\n'; }
    if (trader.isOffLine({ traderStatus: response.traderStatus })) { msg += '\tOffLine,\n'; }
    if (trader.isCompulsionSwitchClose({ traderStatus: response.traderStatus })) { msg += '\tCompulsionSwitchClose,\n'; }
    if (trader.isEtbCommandExecute({ traderStatus: response.traderStatus })) { msg += '\tEtbCommandExecute,\n'; }
    if (trader.isHighTemperatureStop({ traderStatus: response.traderStatus })) { msg += '\tHighTemperatureStop,\n'; }
    if (trader.isNonRecoverableError({ traderStatus: response.traderStatus })) { msg += '\tNonRecoverableError,\n'; }
    if (trader.isAutoCutterError({ traderStatus: response.traderStatus })) { msg += '\tAutoCutterError,\n'; }
    if (trader.isBlackMarkError({ traderStatus: response.traderStatus })) { msg += '\tBlackMarkError,\n'; }
    if (trader.isPaperEnd({ traderStatus: response.traderStatus })) { msg += '\tPaperEnd,\n'; }
    if (trader.isPaperNearEnd({ traderStatus: response.traderStatus })) { msg += '\tPaperNearEnd,\n'; }

    msg += '\tEtbCounter = ' + trader.extractionEtbCounter({ traderStatus: response.traderStatus }).toString() + ' ]\n';

    //      msg += 'Status : [ ' + response.status + ' ]\n';
    //
    //      msg += 'ResponseText : [ ' + response.responseText + ' ]\n';

    alert(msg);
  }

  trader.onError = function (response) {
    var msg = '- onError -\n\n';

    msg += '\tStatus:' + response.status + '\n';

    msg += '\tResponseText:' + response.responseText + '\n\n';

    msg += 'Do you want to retry?\n';

    var answer = window.confirm(msg);

    if (answer) {
      sendMessage(request);
    }
    else {
      // hideNowPrinting();
    }
  }

  trader.sendMessage({ request: request });
}

function onSendAscii() {
  var builder = new StarWebPrintBuilder();

  request = '';

  request += builder.createInitializationElement();

  switch ('inch2') {
    case 'inch2':
      request += builder.createTextElement({ characterspace: 2 });

      request += builder.createAlignmentElement({ position: 'right' });
      request += builder.createLogoElement({ number: 1 });
      request += builder.createTextElement({ data: 'TEL 9999-99-9999\n' });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createAlignmentElement({ position: 'center' });
      request += builder.createTextElement({ data: 'Thank you for your coming. \n' });
      request += builder.createTextElement({ data: "We hope you'll visit again.\n" });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Apple                $20.00\n' });
      request += builder.createTextElement({ data: 'Banana               $30.00\n' });
      request += builder.createTextElement({ data: 'Grape                $40.00\n' });
      request += builder.createTextElement({ data: 'Lemon                $50.00\n' });
      request += builder.createTextElement({ data: 'Orange               $60.00\n' });
      request += builder.createTextElement({ emphasis: true, data: 'Subtotal            $200.00\n' });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ underline: true, data: 'Tax                  $10.00\n' });
      request += builder.createTextElement({ underline: false });

      request += builder.createTextElement({ emphasis: true });
      request += builder.createTextElement({ width: 2, data: 'Total' });
      request += builder.createTextElement({ width: 1, data: '   ' });
      request += builder.createTextElement({ width: 2, data: '$210.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ emphasis: false });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Received            $300.00\n' });

      request += builder.createTextElement({ width: 2, data: 'Change' });
      request += builder.createTextElement({ width: 1, data: '   ' });
      request += builder.createTextElement({ width: 2, data: '$90.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ characterspace: 0 });
      break;
    case 'inch3DotImpact':
      request += builder.createTextElement({ characterspace: 2 });

      request += builder.createAlignmentElement({ position: 'right' });
      request += builder.createLogoElement({ number: 1 });
      request += builder.createTextElement({ data: 'TEL 9999-99-9999\n' });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createAlignmentElement({ position: 'center' });
      request += builder.createTextElement({ data: 'Thank you for your coming. \n' });
      request += builder.createTextElement({ data: "We hope you'll visit again.\n" });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Apple                               $20.00\n' });
      request += builder.createTextElement({ data: 'Banana                              $30.00\n' });
      request += builder.createTextElement({ data: 'Grape                               $40.00\n' });
      request += builder.createTextElement({ data: 'Lemon                               $50.00\n' });
      request += builder.createTextElement({ data: 'Orange                              $60.00\n' });
      request += builder.createTextElement({ emphasis: true, data: 'Subtotal                           $200.00\n' });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ underline: true, data: 'Tax                                 $10.00\n' });
      request += builder.createTextElement({ underline: false });

      request += builder.createTextElement({ emphasis: true });
      request += builder.createTextElement({ width: 2, data: 'Total         $210.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ emphasis: false });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Received                           $300.00\n' });

      request += builder.createTextElement({ width: 2, data: 'Change         $90.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ characterspace: 0 });
      break;
    default:
      request += builder.createTextElement({ characterspace: 0 });

      request += builder.createAlignmentElement({ position: 'right' });
      request += builder.createLogoElement({ number: 1 });
      request += builder.createTextElement({ data: 'TEL 9999-99-9999\n' });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createAlignmentElement({ position: 'center' });
      request += builder.createTextElement({ data: 'Thank you for your coming. \n' });
      request += builder.createTextElement({ data: "We hope you'll visit again.\n" });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Apple                                     $20.00\n' });
      request += builder.createTextElement({ data: 'Banana                                    $30.00\n' });
      request += builder.createTextElement({ data: 'Grape                                     $40.00\n' });
      request += builder.createTextElement({ data: 'Lemon                                     $50.00\n' });
      request += builder.createTextElement({ data: 'Orange                                    $60.00\n' });
      request += builder.createTextElement({ emphasis: true, data: 'Subtotal                                 $200.00\n' });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ underline: true, data: 'Tax                                       $10.00\n' });
      request += builder.createTextElement({ underline: false });

      request += builder.createTextElement({ emphasis: true });
      request += builder.createTextElement({ width: 2, data: 'Total            $210.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ emphasis: false });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Received                                 $300.00\n' });

      request += builder.createTextElement({ width: 2, data: 'Change            $90.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ characterspace: 0 });
      break;
    case 'inch4':
      request += builder.createTextElement({ characterspace: 1 });

      request += builder.createAlignmentElement({ position: 'right' });
      request += builder.createLogoElement({ number: 1 });
      request += builder.createTextElement({ data: 'TEL 9999-99-9999\n' });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createAlignmentElement({ position: 'center' });
      request += builder.createTextElement({ data: 'Thank you for your coming. \n' });
      request += builder.createTextElement({ data: "We hope you'll visit again.\n" });
      request += builder.createAlignmentElement({ position: 'left' });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Apple                                                     $20.00\n' });
      request += builder.createTextElement({ data: 'Banana                                                    $30.00\n' });
      request += builder.createTextElement({ data: 'Grape                                                     $40.00\n' });
      request += builder.createTextElement({ data: 'Lemon                                                     $50.00\n' });
      request += builder.createTextElement({ data: 'Orange                                                    $60.00\n' });
      request += builder.createTextElement({ emphasis: true, data: 'Subtotal                                                 $200.00\n' });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ underline: true, data: 'Tax                                                       $10.00\n' });
      request += builder.createTextElement({ underline: false });

      request += builder.createTextElement({ emphasis: true });
      request += builder.createTextElement({ width: 2, data: 'Total                    $210.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ emphasis: false });

      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ data: 'Received                                                 $300.00\n' });

      request += builder.createTextElement({ width: 2, data: 'Change                    $90.00\n' });
      request += builder.createTextElement({ width: 1 });
      request += builder.createTextElement({ data: '\n' });

      request += builder.createTextElement({ characterspace: 0 });
      break;
  }

  request += builder.createCutPaperElement({ feed: true });

  sendMessage(request);
}


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Entry(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    people: 1,
    tableNo: 1,
    age: '',
    name: 'hai',
  });
  const [qrcodeUrl, setQrcodeUrl] = React.useState('');
  const [qrcodeVisible, setQrcodeVisible] = React.useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangePeople = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: parseInt(event.target.value, 10),
    });
  };

  const handleChangeTableNo = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: parseInt(event.target.value, 10),
    });
  };

  return (
    <div>
      <TextField
        id="outlined-number"
        label="人数"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={state.people}
        onChange={handleChangePeople}
        inputProps={{
          name: 'people',
          // id: 'outlined-age-native-simple',
        }}
      />
      <TextField
        id="outlined-number"
        label="テーブル番号"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={state.tableNo}
        onChange={handleChangeTableNo}
        inputProps={{
          name: 'tableNo',
          // id: 'outlined-age-native-simple',
        }}
      />
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">テーブル番号</InputLabel>
        <Select
          native
          value={state.age}
          onChange={handleChange}
          label="テーブル番号"
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={() => {
          onSendAscii();
        }}
      >
        test
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={() => {
          const url = window.location.protocol + '//' + window.location.host +
            '/demo/order/' + state.people + '/' + state.tableNo;
          setQrcodeUrl(url);
          setQrcodeVisible(true);
        }}
      >
        伝票発行
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={() => {
          var url = "http://localhost:8001/StarWebPRNT/SendMessage";
          var papertype = "";

          // var webPrintBuilder = require('../lib/StarWebPrintBuilder.js').default;
          var builder = new StarWebPrintBuilder();
          request = '';

          request += builder.createTextElement({ characterspace: 0, international: 'japan' });

          request += builder.createAlignmentElement({ position: 'right' });
          request += builder.createLogoElement({ number: 1 });
          request += builder.createTextElement({ data: '伝票番号 20210501-1908773838\n' });
          request += builder.createAlignmentElement({ position: 'left' });

          request += builder.createTextElement({ data: '\n' });

          //request += builder.createAlignmentElement({position:'center'});
          request += builder.createTextElement({ data: 'テーブル番号:' + state.tableNo + '\n' });
          // request += builder.createTextElement({ data: '数量予約も承っております。お気軽にご連絡ください。\n' });
          //request += builder.createAlignmentElement({position:'left'});

          request += builder.createTextElement({ data: '\n' });

          request += builder.createAlignmentElement({ position: 'center' });

          request += builder.createTextElement({ data: '\n' });
          request += builder.createQrCodeElement({ model: 'model2', level: 'level_l', cell: 3, data: 'https://nikomi.fun/demo/' });
          request += builder.createTextElement({ data: '\n' });
          request += builder.createTextElement({ data: '\n' });
          request += builder.createCutPaperElement({ feed: true });

          // var webPrintTrader = require('../lib/StarWebPrintTrader.js');
          var trader = new StarWebPrintTrader({ url: url, papertype: papertype });
          trader.onReceive = function (response) { alert(response.responseText); }
          trader.onError = function (response) { alert(response.responseText); }
          trader.sendMessage({ request: request });
        }}
      >
        印刷
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={() => {
          const linkto = encodeURI('http://nikomi.fun/demo/entry/');
          const url = 'webprnt://starmicronics.com/open?url=' + linkto;
          window.location.href = url;
        }}
      >
        伝票印刷
      </Button>
      <QRCode
        value={qrcodeUrl}
        style={{ display: qrcodeVisible ? '' : 'none' }}
      />
      <Link to={`./order/${state.people}/${state.tableNo}`}>
        <Button variant="contained" color="primary">
          Orderへ
        </Button>
      </Link>
    </div>
  );
}
