// react component
import React from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui component
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// library
import axios from 'axios';
import _ from 'lodash';

// original component
import MenuCard from '../component/MenuCard';

// menu data
import MenuJson from '../data/menu.json';
import OrderDialog from "../component/OrderDialog";

function TabPanel(props) {
  const {
    children,
    value,
    index,
    content,
    menus,
    handleClickOpen,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {/*{value === index && (*/}
        <Box p={3}>
          {/*<Typography>{children}</Typography>*/}
          <Typography>{content}</Typography>
        </Box>
      {/*)}*/}
      {/*{value === index && (*/}
        <Grid container>
          <Grid container item xs={12} spacing={3}>
            {menus.map((item) => {
              return (
                <>
                  <Grid item xs={6} sm={3}>
                    <MenuCard
                      menuItem={item}
                      subMenuItem={item.sub_menus}
                      menuSubKindItem={item.menu_sub_kind}
                      handleOpen={handleClickOpen}
                    />
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      {/*)}*/}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

export default function Order(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {slipNo} = useParams();
  const [slip, setSlip] = React.useState({});
  const [menus, setMenus] = React.useState([]);
  const [subMenus, setSubMenus] = React.useState([]);
  const [menuKinds, setMenuKinds] = React.useState([]);
  const [menuSubKinds, setMenuSubKinds] = React.useState([]);
  const [menu, setMenu] = React.useState(MenuJson);
  const [openOrderDialog, setOpenOrderDialog] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState({
    menu: {
      menu_name: '',
    }
  });
  const [value, setValue] = React.useState(0);

  const filterMenus = value => _.filter(menus, {menu_kind_id: value});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClickOpen = (menu, subMenu, menuSubKind) => {
    setSelectedMenu({menu, subMenu, menuSubKind});
    setOpenOrderDialog(true);
  };

  const handleClose = () => {
    setOpenOrderDialog(false);
  };

  React.useEffect(() => {
    const fetchMenuData = async () => {
      const result = await axios(
        'http://192.168.0.8:8000/api/menu/' + slipNo,
      );
      setSlip(result.data.slip);
      setMenus(result.data.menus);
      setMenuKinds(result.data.menuKinds);
      console.log('set menus response to data! ');
    };

    fetchMenuData();
  }, []);
  return (
    <div>
      {slip.people}人 {slip.table_no}番テーブル
      <React.Fragment>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {menuKinds.map((menuKind, index) => {
              return (
                <Tab
                  label={menuKind.menu_kind_name}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {menuKinds.map((menuKind, index) => {
            return (
              <TabPanel
                value={value}
                index={index}
                dir={theme.direction}
                content={menuKind.menu_kind_name}
                menus={filterMenus(menuKind.menu_kind_id)}
                handleClickOpen={handleClickOpen}
              >
                {menuKind.menu_kind_name}
              </TabPanel>
            );
          })}
        </SwipeableViews>
        <OrderDialog
          open={openOrderDialog}
          slip={slip}
          selectedMenu={selectedMenu}
          handleClose={handleClose}
        />
      </React.Fragment>
    </div>
  );
}
